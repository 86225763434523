import React, { Component } from 'react';
import { isMobileDevice } from "../../helper/isMobileDevice";
import classnames from 'classnames';
//assets
import line_desktop from '../../../static/images/integrations/line_desktop.svg';
import line_mobile from '../../../static/images/integrations/line_mobile.svg';
import ScheduleDemoButton from '../scheduleDemo/ScheduleDemoButton';


export default class Line extends Component {

    state = {
        is_mobile: false
    }

    componentDidMount(){
        if (isMobileDevice()) {
            this.setState({
                is_mobile: true
            })
        }
    }

    render() {
        const {is_mobile} = this.state;
        return (
            <div className={classnames('columns flex-wrap no-margin-left integrations', {'is-fullwidth': is_mobile})}>
                <div className="column is-12 columns no-padding-integrations no-margin-left center-align-items">
                    <div className="column is-5 columns flex-wrap no-margin-left no-padding-left">
                        <div className={classnames("is-12 columns column flex-wrap mg-top-n-30", {'pd-left-70': !is_mobile}, {'has-text-centered no-padding-left': is_mobile})}>
                            <p className={classnames("has-text-weight-bold line-height-1 mg-bottom-20 column is-12 mg-bottom-10 integrations-heading-color", {'font-48 is-pulled-left': !is_mobile}, {'title is-3': is_mobile})}>
                            Reachout where your people are!
                            </p>
                            <p className={classnames("is-pulled-left column is-12 no-padding-top no-padding-bottom mg-bottom-10 integrations-sub-text-color", {'font-18': !is_mobile}, {'font-12 mg-left-right-10 mg-bottom-20': is_mobile})}>
                            With Amber's LINE integration, you can boost your response rates by over 20%. Amber smoothly fits into your employees' natural way of communicating.
                            </p>
                            <p className={classnames('column is-12 no-padding-top no-padding-bottom integrations-sub-text-color', {'font-12': is_mobile})}>
                                Interested? Sign up with email :)
                            </p>
                        </div>
                        <div className={classnames({'is-11 column has-text-left  pd-left-70': !is_mobile, 'has-text-centered': is_mobile})}>
                            <div className="is-hidden-mobile"><ScheduleDemoButton initial_email_enabled={true}/></div>
                            <div className="is-hidden-tablet"><ScheduleDemoButton/></div>
                        </div>
                    </div>
                    <div className="column is-7 no-padding-integrations">
                        <img className={classnames("mg-bottom-30", {'is-pulled-right': !is_mobile}, {'is-pulled-left': is_mobile})} src={is_mobile ? line_mobile : line_desktop} alt="Slack"/>
                    </div>
                </div>
            </div>
        )
    }
}
